import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    colours,
} from '../../styles/global'

const Instagram = (props) => {
    return (
        <Container
            as={'a'}
            href="https://www.instagram.com/galeriemain/"
            target="_blank"
        >
            <Card>
                <Image />
                <Color />
            </Card>
        </Container>
    )
}

// Shared

const Image = styled.div``
const Color = styled.div``
const Card = styled.div``

// Layout

const Container = styled.a`
    flex-direction: column;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.08));
    width: fit-content;
    position: fixed;
    bottom: 30px;
    right: 30px;

    perspective: 250px;
    transform-origin: bottom right;
    transition: 0.8s ease;

    &:hover {
        transform: scale(1.4);
    }

    ${Card} {
        transform-style: preserve-3d;
        animation: clockwise 12s infinite linear;

        ${Image} {
            background-image: url(${require('../../assets/images/instagram.jpg')});
            ${bgIcon}
            width: 100px;
            height: 213px;
            z-index: 20;
            position: relative;
            border-radius: 8px;
            backface-visibility: hidden;

            ${media.phone`
				 width: 60px;
				 height: 153px;
			`}
        }

        ${Color} {
            background-color: ${colours.blue};
            width: 100px;
            height: 213px;
            position: absolute;
            top: 0;
            z-index: 10;
            transform: rotateY(180deg);
            border-radius: 8px;
            backface-visibility: hidden;

            ${media.phone`
				 width: 60px;
				 height: 153px;
			`}

            ${(props) => {
                if (props.theme.main == 'dark')
                    return css`
                        background-color: ${colours.black};
                    `
            }}

            &:before {
                content: 'Instagram @galeriemain';
                font-size: 12px;
                line-height: 1.1;
                color: white;
                position: absolute;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
`

export default Instagram
