import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css, ThemeProvider } from 'styled-components'
import Transition from '../../transition'
import { useMount } from 'react-use'

import { MetaTags, Header, Splash } from '../'
import { media } from '../../styles/utils'
import { GlobalStyles, colours } from '../../styles/global'

const Layout = ({ children, meta, hideHeader }) => {
    const [theTheme, setTheme] = useState('light')

    const toggleTheme = () => {
        if (theTheme === 'light') {
            window.localStorage.setItem('theme', 'dark')
            setTheme('dark')
        } else {
            window.localStorage.setItem('theme', 'light')
            setTheme('light')
        }
	}

    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme')
		localTheme && setTheme(localTheme)
    }, [])

    return (
        <ThemeProvider theme={{ main: theTheme }}>
			<>
				<Splash />

                <MetaTags {...meta} />

                {!hideHeader && <Header />}
                {!hideHeader && <Toggle onClick={toggleTheme} />}

				<Wrapper>
					<Transition>{children}</Transition>
				</Wrapper>
            </>
        </ThemeProvider>
    )
}

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;

    ${(props) => {
        if (props.theme.main == 'dark')
            return css`
                background: ${colours.blue};
            `
    }}
`

const Toggle = styled.div`
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 40px;
    width: 20px;
    height: 20px;
    background: ${colours.blue};
    border-radius: 50%;

    ${media.phone`
		top: 20px;
		right: 20px;
	`}

    ${(props) => {
        if (props.theme.main == 'dark')
            return css`
                background: ${colours.white};
            `
    }}

    &:hover {
        transform: scale(1.1);
    }
`

export default Layout
