import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql, navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useMount, useLocation } from 'react-use'
import { forEach, filter, find, remove } from 'lodash'
import { getExhibitionTags } from '../../utils'
import Scroll from 'react-scroll'
import { Link as ScrollLink } from 'react-scroll'
import {
    Element,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll'
import { motion, AnimatePresence } from 'framer-motion'

import { isClient, media } from '../../styles/utils'
import {
    container,
    bgImage,
    bgIcon,
    padding,
    hoverState,
    colours,
    type,
} from '../../styles/global'

const Accordion = (props) => {
    const { acf, slug, title, grid, url, image, text } = props

	const [active, setActiveState] = useState(false)
	const [animate, setAnimate] = useState(false)
	const [height, setHeightState] = useState('0px')

	const content = useRef(null)

    // Toggle

	function toggleAccordion(e, to) {
		e.preventDefault()
		e.stopPropagation()

		if (!active) {
			setActiveState(true)
		} else {
			setActiveState(false)
		}

		setHeightState(content.current.scrollHeight)

		scroller.scrollTo(to, {
            duration: 1500,
			smooth: 'circOut',
			offset: -100,
        })
    }

    // Render Grid

    const renderGrid = () => {
        if (!grid) return

        const items = grid.map((block, i) => {
            return (
                <GridItem as={Link} to={`${url}/${block.slug}`} key={i}>
					<Title>{block.acf.type && <>
						{ block.acf.type }: </>}
						{block.title}
					</Title>

                    {block.acf.main_image && (
                        <Image
                            key={block.acf.main_image.sizes.medium2}
                            src={block.acf.main_image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{
                                            opacity: loading ? 0 : 1,
                                        }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </GridItem>
            )
        })

        return <Grid>{items}</Grid>
    }

    // Render Text

    const renderText = () => {
        if (!text) return

        return (
            <Paragraph
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
    }

    return (
        <Container>
			<Meta
				id={title}
                active={active.toString()}
				onClick={(e) => toggleAccordion(e, title)}
			>
				<Icon active={active.toString()}>
					<Line/>
					<Line/>
                </Icon >
                <Title className={active ? 'active' : ' '}>{title}</Title>
			</Meta>
			
			<AnimatePresence initial={false}>
					<Content
						ref={content}
						animate={active ? 'show' : 'hide'} variants={animatedItem} custom={height} exit="hide"
					>
						{renderGrid()}

						<Wrapper>
							{renderText()}
							{image && (
								<Image
									key={image.sizes.medium2}
									src={image.sizes.medium2}
								>
									{(src, loading) => {
										return (
											<BGImage
												image={src}
												style={{
													opacity: loading ? 0 : 1,
												}}
											/>
										)
									}}
								</Image>
							)}
						</Wrapper>
					</Content>
			</AnimatePresence>
        </Container>
    )
}

// Shared

const Title = styled.div``
const Prompt = styled.div``
const Grid = styled.div``
const GridItem = styled.div``
const Paragraph = styled.div``
const Wrapper = styled.div``
const Line = styled.div``
const Label = styled.div``
const Item = styled.div``

// Base

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    &:last-of-type {
        ${Wrapper} {
            padding-bottom: 0px;
        }
    }
`

// Meta

const Meta = styled(props => <ScrollLink {...props}/>)`
    display: flex;
	position: relative;
    cursor: pointer;

    &:hover {
        ${Title} {
			transform: translateX(120px);

			${media.phone`
				transform: none;
			`}
        }
	}
	
	${Title} {
		${type.heading2}
		margin-left: -8px;
		line-height: 1;
		background: ${colours.white};
		z-index: 20;
		transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);

		${(props) => {
			if (props.theme.main == 'dark')
				return css`
					color: ${colours.white};
					background: ${colours.blue};
				`
		}}
		
		${(props) => {
			if (props.active == 'true')
				return css`
					transform: translateX(120px);

					${media.phone`
						padding-bottom: 20px;
						transform: translateX(0) !important;
					`}
				`
		}}

		${media.phone`
			font-size: 42px;
			margin: 0;
			line-height: 45px;
			transform: translateX(0);
			padding-left: 8px;
		`}
	}
`

const Icon = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 10;
	transform: translateY(-50%);
	margin-top: -8px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${media.phone`
		position: relative;
		transform: none;
		margin: 0;
		padding-bottom: 7px;
	`}

	${(props) => {
		if (props.active == 'true')
			return css`
				${media.phone`
					padding-bottom: 26px;
				`}
			`
	}}

	${Line} {
		width: 90px;
		height: 6px;
		background: ${colours.blue};
		transform-origin: center;

		${media.phone`
			width: 30px;
			height: 4px;
		`}

		${(props) => {
		if (props.theme.main == 'dark')
				return css`
					background: ${colours.white};
				`
		}}

		&:first-child {
			transform: rotate(90deg);

			${(props) => {
				if (props.active == 'true')
					return css`
						opacity: 0;
					`
			}}
		}

		&:nth-child(2){
			transform: translateY(-8px);

			${media.phone`
				transform: translateY(-4px);
			`}
		}
	}

`

// Content

const Content = styled(motion.div)`
	overflow: hidden;
	
    ${Grid} {
        display: flex;
        flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 40px;

        ${media.phone`
			flex-direction: column;
		`}

        ${GridItem} {
            flex: 0 1 calc(33.33% - 2px);
            display: flex;
			flex-direction: column;
			padding-bottom: 30px;

            ${media.tablet`
				flex: 0 1 calc(50% - 4px);
				padding-bottom: 20px;
			`}

            ${media.phone`
				flex: 0 1 100%;
			`}

			${Title} {
				padding-bottom: 12px;
				position: relative;

				${(props) => {
					if (props.theme.main == 'dark')
						return css`
							color: ${colours.white};
						`
				}}
			}

            ${BGImage} {
                width: 100%;
                height: 100%;
                min-height: 460px;
				filter: grayscale(1);
				transition: 1s filter linear, 1s -webkit-filter linear;

                ${media.phone`
					margin-bottom: 20px;
					min-height: 300px;
				`}

                &:hover {
					filter: grayscale(0);
                }
			}
        }
	}
	
	${Wrapper} {
		display: flex;
		flex-direction: column;
		padding-bottom: 40px;

		${Paragraph} {
			max-width: calc(33.33% - 2px);

			${media.phone`
				width: 100%;
				max-width: 100%;
			`}

			${(props) => {
                if (props.theme.main == 'dark')
                    return css`
                        color: ${colours.white};
                    `
			}}
			
			a {
				width: calc(100% + 0.1px);
				background-image: linear-gradient(
					transparent calc(100% - 1px),
					${colours.blue} 1px
				);
				background-repeat: no-repeat;
				background-size: 0 100%;
				-webkit-transition: background-size 0.6s;
				transition: background-size 0.6s;
				padding-bottom: 2px;

				${(props) => {
                    if (props.theme.main == 'dark')
                        return css`
                            color: ${colours.white};
                            background-image: linear-gradient(
                                transparent calc(100% - 1px),
                                ${colours.white} 1px
                            );
                        `
                }}

				&:hover {
					background-size: 100% 100%;
				}
			}
		}

		${BGImage} {
			width: 100%;
			max-width: calc(33.33% - 2px);
			min-height: 460px;
			filter: grayscale(1);
			transition: 1s filter linear, 1s -webkit-filter linear;

			${media.phone`
				max-width: 100%;
				min-height: 300px;
			`}

			&:hover {
				filter: grayscale(0);
			}
		}
	}
`

const animatedItem = {
	hide: {
		opacity: 0,
		height: 0,
		transition: {
			duration: 0.7,
			ease: 'circOut'
		},
	},
	show: height => ({
		opacity: 1,
		height: height + 'px',
		transition: {
			duration: 0.7,
			ease: 'circOut'
		},
	}),
}

export default Accordion
