import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { useLocation, useWindowScroll, useMount } from 'react-use'
import styled, { css, createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'
import times from 'lodash/times'

import { media } from '../../styles/utils'
import { container, padding, colours, bgIcon } from '../../styles/global'
import { isClient } from '../../styles/utils'

const Splash = (props) => {
    const [visible, setVisible] = useState(false)
    const [animate, setAnimate] = useState(false)

    // Set splash visibility / Local storage

    let timer

    useMount(() => {
        if (isClient()) {
            let splash = document.getElementById('splash')

            if (sessionStorage.introSeen == 'true') {
                if (splash) {
                    splash.classList.add('transition')
                    splash.classList.remove('active')
                }
            } else {
                if (splash) {
                    splash.classList.add('active')
                }

                setAnimate(true)
                setVisible(true)
                document.body.style.overflow = 'hidden'
            }
        }
    })

    const seenSplash = () => {
        if (sessionStorage.introSeen !== 'true') {
            clearInterval(timer)

            let splash = document.getElementById('splash')

            if (splash) {
                splash.classList.add('transition')
                splash.classList.remove('active')
            }

            setTimeout(() => {
				sessionStorage.introSeen = true
				setVisible(false)
                document.body.style.overflow = 'auto'
            }, 1600)
        }
    }

    return (
        <>
            <SplashStyles />
            <Wrapper
                id={'splash'}
                animate={visible ? 'show' : 'hidden'}
				variants={animatedContainer}
                onAnimationComplete={seenSplash}
			>
				{times(5, Number).map((i) => (
					<Logo key={i} active={animate}><span>MAIN</span></Logo>
				))}
            </Wrapper>
        </>
    )
}

// Shared
const WordWrap = styled(motion.div)``
const Sentence = styled(motion.div)``
const Word = styled(motion.h2)``

const SplashStyles = createGlobalStyle`
    #splash {
	    opacity: 0;
        pointer-events: none;
		/*clip-path: inset(0 0 100% 0);*/
		
        &.active {
            opacity: 1;
            pointer-events: none;
			/*clip-path: inset(0 0 0 0);*/
		}

		&.transition {
			/*opacity: 0;*/
			transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
			transition-delay: 2s;
		}
    }
`

// Wrapper

const Wrapper = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: ${colours.blue};
    z-index: 800;
    opacity: 0;
	z-index: 400;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	transform: scale(2);

    ${media.phone`
		padding: 12px;
		height: 100%;
		min-height: fill-available;
		transform: scale(1);
	`}
`

const Logo = styled(motion.div)`
	font-size: 100px;
	line-height: 75px;
	text-align: center;
	color: ${colours.white};
	background-color: ${colours.blue};
	border: 2px solid ${colours.blue};
	box-sizing: content-box;

	transition: all 1.5s cubic-bezier(0.4, 0, 0.2, 1);
	transition-delay: 0.6s;

	${media.phone``}

	${(props) => {
		if (props.active)
			return css`
				&:nth-child(5n + 1) {
					transform: translateY(46px) !important;
				}

				&:nth-child(5n + 2) {
					transform: translateY(23px) !important;
				}

				&:nth-child(5n + 4) {
					transform: translateY(-23px) !important;
				}

				&:nth-child(5n + 5) {
					transform: translateY(-46px) !important;
				}
			`
	}}
	
	span {
		transform: translateY(10px);
		position: relative;
		display: block;
	}
	
	&:nth-child(5n + 1) {
		transform: translateY(160px);
		z-index: 0;
	}
	&:nth-child(5n + 2) {
		transform: translateY(78px);
		z-index: 5;
	}
	&:nth-child(5n + 3) {
		z-index: 10;
	}
	&:nth-child(5n + 4) {
		transform: translateY(-78px);
		z-index: 5;
	}
	&:nth-child(5n + 5) {
		transform: translateY(-160px);
		z-index: 0;
	}

`

const animatedContainer = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.05,
        },
    },
}

export default Splash
