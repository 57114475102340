import React, { useState } from 'react'
import { useStaticQuery, graphql, navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import times from 'lodash/times'

import { media } from '../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    colours,
} from '../../styles/global'
import { parseACF } from '../../utils'

const Header = (props) => {
	const data = parseACF(useStaticQuery(query), 'allWordpressGlobal')
	 const [animate, setAnimate] = useState(false)

    return (
        <Container>
			<Wrap 
				animate={animate ? 'show' : 'hidden'}
				variants={animatedContainer}
				onMouseEnter={() => setAnimate(true)}
				onMouseLeave={() => setAnimate(false)}
			>
				<AnimatePresence initial={false}>				
					{times(3, Number).map((i) => (
						<Logo key={i} animate={animate ? 'show' : 'hidden'} variants={animatedItem} custom={i} style={{ zIndex: 3 - i}}/>
					))}
				</AnimatePresence>
            </Wrap>
        </Container>
    )
}

// Shared

const Logo = styled(motion.div)``
const Description = styled.div``
const Contact = styled.div``
const Wrap = styled(motion.div)``

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    position: absolute;

    ${Wrap} {
        position: absolute;
        top: 30px;
        left: 30px;

        display: flex;
        flex-direction: column;

        ${media.phone`
			top: 20px;
			left: 20px;
		`}

        ${Logo} {
            width: 135px;
            height: 40px;
            background-image: url(${require('../../assets/images/logo.svg')});
            ${bgIcon}
			cursor: pointer;
            background-color: ${colours.white};
            border: 2px solid ${colours.white};
            box-sizing: content-box;

            ${media.phone`
				width: 112px;
				height: 34px;
			`}

            ${(props) => {
                if (props.theme.main == 'dark')
                    return css`
                        background-image: url(${require('../../assets/images/logo-black.svg')});
                        background-color: ${colours.blue};
                        border-color: ${colours.blue};
                    `
			}}
			
			&:nth-last-child(-n+2) {
				${media.phone`
					display: none;
				`}
			}

        }
    }
`

// Functions

export const query = graphql`
    query {
        allWordpressGlobal {
            nodes {
                acf_json
            }
        }
    }
`

const animatedContainer = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
        },
    },
}

const animatedItem = {
	hidden: i => ({
		y: '-' + i * 44 + 'px',
		transition: {
			duration: 0.8,
			ease: 'circOut'
		},
	}),
	show: i => ({
		y: 0,
		transition: {
			duration: 1,
			ease: 'circOut'
		},
	}),
}

export default Header
